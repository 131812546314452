<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
    <el-row>
      <el-tabs v-model="activeName" @tab-click="changeTab">
        <el-tab-pane label="一级类目" name="first">
          <el-button
            type="primary"
            style="padding: 1rem 2.5rem; margin-bottom: 1rem"
            @click="openDialog()"
            >添加一级类目</el-button
          >
          <el-table
            :data="typeList"
            :header-cell-class-name="'table-header'"
            v-loading="is_loading"
            row-key="id"
            :tree-props="{ hasChildren: 'hasChildren', children: 'childList' }"
          >
            >

            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="名称" prop="name"> </el-table-column>s
            <el-table-column label="图片" prop="">
              <template slot-scope="scope">
                <el-image :src="scope.row.pic" style="width: 3rem"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="状态" prop="">
              <template slot-scope="scope">
                {{ scope.row.status == 1 ? "不可用" : "可用" }}
              </template>
            </el-table-column>
            <el-table-column label="排序" prop="weights"> </el-table-column>
            <el-table-column
              label="添加时间"
              prop="create_time"
            ></el-table-column>
            <el-table-column
              label="更新时间"
              prop="update_time"
            ></el-table-column>

            <el-table-column label="操作" prop="" width="180px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="small"
                  @click="if_delete(scope.row)"
                  >删除</el-button
                >
                <el-button type="warning" size="small" @click="edit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :total="typeTotal"
            :size="10"
            :current-page="currentPage"
            @current-change="changeCurrentPage"
          >
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="二级类目" name="second">
          <el-button
            type="primary"
            style="padding: 1rem 2.5rem; margin-bottom: 1rem"
            @click="openDialog()"
            >添加二级子类目</el-button
          >
          <el-table
            :data="typeList"
            :header-cell-class-name="'table-header'"
            v-loading="is_loading"
            row-key="id"
            :tree-props="{ hasChildren: 'hasChildren', children: 'childList' }"
          >
            >
            <el-table-column label="ID" prop="id"></el-table-column>
            <el-table-column label="名称" prop="name"> </el-table-column>s
            <el-table-column label="图片" prop="">
              <template slot-scope="scope">
                <el-image :src="scope.row.pic" style="width: 3rem"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="排序" prop="weights"> </el-table-column>
            <el-table-column
              label="添加时间"
              prop="create_time"
            ></el-table-column>
            <el-table-column
              label="更新时间"
              prop="update_time"
            ></el-table-column>

            <el-table-column label="操作" prop="" width="180px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="small"
                  @click="if_delete(scope.row)"
                  >删除</el-button
                >
                <el-button type="warning" size="small" @click="edit(scope.row)"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="fyb">
            <el-pagination
              :total="typeTotal"
              :size="10"
              :current-page="currentPage"
              @current-change="changeCurrentPage"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-row>

    <!-- 确认删除 -->
    <el-dialog
      title="提示"
      :visible.sync="confirmDelete"
      width="20%"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <div style="width: 100%; text-align: center">确认删除?</div>
      <span
        slot="footer"
        class=""
        style="display: flex; justify-content: space-around"
      >
        <el-button type="danger" @click="deleteType">确 定</el-button>
        <el-button type="primary" @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog
      title="添加类目"
      :visible.sync="addVisible"
      :width="ks ? '95%' : '35%'"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form :model="form" id="new-good-form" :rules="rules">
          <el-form-item label-width="8rem" label="分类名称" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label-width="8rem" label="排序" prop="categories_child">
            <el-input v-model="form.weights"></el-input>
          </el-form-item>
          <el-form-item label-width="8rem" label="图片">
            <avatar-uploader
              @getImgUrl="getCover1"
              :url="form.pic"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label-width="8rem" label="类目级别">
            <el-radio v-model="form.type" :label="1">一级类目</el-radio>
            <el-radio v-model="form.type" :label="2">二级类目</el-radio>
          </el-form-item>
          <el-form-item label-width="8rem" label="父类目" v-if="form.type == 2">
            <el-select
              remote
              :remote-method="getCategoriesChild"
              v-model="form.parent_id"
              @focus.once="getCategoriesChild()"
            >
              <el-option
                v-for="item in addTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addNewType()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改类目 -->
    <el-dialog
      title="修改类目"
      :visible.sync="updateVisible"
      :width="ks ? '95%' : '35%'"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-form :model="dform" id="new-good-form" :rules="rules">
          <el-form-item label-width="8rem" label="类目名称" prop="name">
            <el-input v-model="dform.name" placeholder="类目名称"></el-input>
          </el-form-item>
          <el-form-item label-width="8rem" label="类目图片" prop="name">
            <avatar-uploader
              @getImgUrl="getCover"
              :url="dform.pic"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label-width="8rem" label="排序" prop="排序">
            <el-input
              v-model="dform.weights"
              :placeholder="dform.weights"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="update()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 绑定子类目 -->
    <el-dialog
      title="绑定科目"
      :visible.sync="bindVisible"
      width="35%"
      :before-close="handleClose"
    >
      <div class="dialog-body">
        <el-transfer
          filterable
          filter-placeholder="请选择绑定的科目"
          v-model="haveCate"
          :data="categories_list"
          :props="{
            key: 'name',
            label: 'name',
          }"
          :titles="['所有类目', '拥有类目']"
        >
        </el-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="bind()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import avatarUploader from "../../components/avatarUploader.vue";
export default {
  components: {
    avatarUploader,
  },
  data() {
    return {
      nav: { firstNav: "商户管理中心", secondNav: "类目管理" },
      temp: {},
      is_loading: false,
      if_clear: false,
      source: "课程",
      addVisible: false,
      updateVisible: false,
      bindVisible: false,
      deleteId: null,
      confirmDelete: false,
      currentPage: 1,
      typeTotal: 0,
      typeList: [],
      categories_list: [],
      form: {},
      addTypeList: [],
      rules: {},
      haveCate: [],
      activeName: "first",
      dform: {},
    };
  },
  computed: {},
  methods: {
    getCover(v) {
      this.dform.pic = v;
    },
    getCover1(v) {
      this.form.pic = v;
    },
    changeTab(tab, event) {
      this.is_loading = true;
      console.log(tab);
      this.typeList = [];
      this.currentPage = 1;
      this.getTypeList();
    },
    changeCurrentPage(v) {
      this.currentPage = v;
      this.getTypeList();
    },

    getCategoriesChild() {
      this.search_loading = true;
      this.editableId = null;
      let url = "/user/mechanismCategory/queryManagerListPage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            // mechanism_id: this.$id,
            type: 1,
            source: "商户",
          },
        })
        .then((res) => {
          this.addTypeList = res.data.data.rows;
          this.search_loading = false;
        });
    },

    getTypeList() {
      this.is_loading = true;
      let type = 1;
      switch (this.activeName) {
        case "first":
          type = 1;
          break;
        case "second":
          type = 2;
          break;
      }
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type,
            source: "商户",
          },
        })
        .then((res) => {
          this.typeList = res.data.data;

          // this.typeTotal = res.data.data.total;
          this.is_loading = false;
        });
    },

    if_delete(row) {
      this.dform = row;
      this.confirmDelete = true;
    },
    deleteType() {
      let url = "/user/mechanismCategory/update";
      this.$axios
        .post(url, {
          id: this.dform.id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "warning", message: "删除成功!" });
            this.confirmDelete = false;
            this.getTypeList();
          }
        });
    },
    update() {
      let url = "/user/mechanismCategory/update";
      this.$axios
        .post(url, {
          id: this.dform.id,
          name: this.dform.name ? this.dform.name : null,
          pic: this.dform.pic ? this.dform.pic : null,
          weights: this.dform.weights,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({ type: "success", message: "更新成功!" });
            this.temp = {};
            this.updateVisible = false;
            this.getTypeList();
          }
        });
    },
    edit(row) {
      this.updateVisible = true;
      this.dform = row;
    },

    getAvatarUrl(v) {
      this.form.pic = v;
    },
    handleClose(done) {
      this.if_clear = false;
      this.$confirm("确认关闭？")
        .then((_) => {
          this.addVisible = false;
          this.updateVisible = false;
          this.confirmDelete = false;
          this.bindVisible = false;
          this.form = {};
          this.temp = {};
          this.if_clear = true;
          done();
        })
        .catch((_) => {});
    },

    openDialog() {
      this.form = {};
      this.dform = {};
      this.addVisible = true;
      switch (this.activeName) {
        case "first":
          this.form.type = 1;
          break;
        case "second":
          this.form.type = 2;
          break;
      }
    },
    addNewType() {
      if (this.form.type == 2 && !this.form.parent_id) {
        this.$message({ message: "请选择父类目" });
        return;
      }
      this.if_clear = false;
      let data = {
        name: this.form.name ? this.form.name : null,
        parent_id: this.form.parent_id ? this.form.parent_id : 0,
        pic: this.form.pic,
        type: this.form.type,
        status: 2,
        source: "商户",
        weights: this.form.weights,
      };
      let url = "/user/mechanismCategory/insert";
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "添加成功!" });
          this.form = {};
          this.addVisible = false;
          this.if_clear = true;
          this.getTypeList();
          this.getTypeList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 1,
          },
        })
        .then((res) => {
          this.categories_list = res.data.data.rows;
        });
    },
  },
  mounted() {
    this.ks = window.screen.width < 768;
    this.getTypeList();
    // this.getCategoriesList()
  },
};
</script>

<style></style>
